export const SECTION_VIEW_TWO_TEXT = {
  common: {
    firstTitle: "SmallOfferView_FirstTitle",
    mainTitle: {
      title: "SmallOfferView_MainTitle",
      price: "SmallOfferView_MainTitle_price",
      afterPrice: "SmallOfferView_MainTitle_AfterPrice",
    },
    secondTitle: "SmallOfferView_SecondTitle",
    bottomTitle: "SmallOfferView_BottomTitle",
    button: {
      path: "SmallOfferView_ButtonPath",
      text: "SmallOfferView_Button",
    },
  },
  extpro : {
    firstTitle: "SmallOfferView_extpro_FirstTitle",
    mainTitle: {
      title: "SmallOfferView_extpro_MainTitle",
      price: "SmallOfferView_extpro_MainTitle_price",
      afterPrice: "SmallOfferView_extpro_MainTitle_AfterPrice",
    },
    secondTitle: "SmallOfferView_extpro_SecondTitle",
    bottomTitle: "SmallOfferView_extpro_BottomTitle",
    button: {
      path: "SmallOfferView_extpro_ButtonPath",
      text: "SmallOfferView_extpro_Button",
    },
  }
}
export const ADDITIONAL_KEYS_FOR_SECTION_TWO = ["extpro"];