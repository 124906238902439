import React, { useEffect, useState } from "react";
import { ERRORS } from "../helpers/errors";
import { USER_STATUSES } from "../components/ext-questionnaire/index.content";
import OgImg from "../assets/images/raster/pages/amazon-keyword-tools/mainscreen_keyword_tools.png";
import { PageContext } from "../store/context/page-context";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { log } from "../helpers";
import Layout from "../layouts/LayoutEmpty";
import Seo from "../components/Seo";
import Container from "../components/common/Container";
import CenterBlock from "../components/common/CenterBlock";
import OfferView from "../components/ext-questionnaire/Offer";
import LinkView from "../components/ext-questionnaire/Link";
import "../components/ext-questionnaire/index.scss";
import FeedbackViewContainer from "../containers/ext-questionnaire/FeedbackViewContainer";
import SelectViewContainer from "../containers/ext-questionnaire/SelectViewContainer";

const ExtQuestionnaire = ({ pageContext }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("unauthorized");
  const [isRendered, setIsRendered] = useState(false);
  const [getCurrentView, setCurrentView] = useState(0);
  const { viewOrder, software, eventCategory, i18Key: page } = pageContext;
  const views = {
    bigOfferView: <OfferView isFirstView />,
    smallOfferView: <OfferView />,
    feedback: <FeedbackViewContainer software={software} />,
    linkView: <LinkView />,
  };
  useEffect(() => {
    try {
      const status = new URL(window.location.href).searchParams.get("status");
      status && setStatus(USER_STATUSES[status]);
    } catch (error) {
      log(error, ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS, true);
    } finally {
      setIsRendered(true);
    }
  }, []);

  return (
    <Layout className="mainExtQuestionnaire" isShowHelp>
      <PageContext.Provider
        value={{
          eventCategory,
          isCn: t("code") === "zh",
          page,
          status,
          translate: t,
        }}
      >
        <Seo
          title="Amazon Product Research Tool & Niche Finder | AMZScout"
          description="Make Amazon product research quick and easy with accurate research software from AMZScout. Analyze niches and find the right products to help you make money."
          page={page}
          manifest="browserconfig.xml"
          ogImg={OgImg}
        />
        {isRendered ? (
          <Container>
            <CenterBlock>
              {getCurrentView === 0 && (
                <SelectViewContainer switchCurrentView={setCurrentView} />
              )}
              {getCurrentView > 0 && views[viewOrder[getCurrentView - 1]]}
            </CenterBlock>
          </Container>
        ) : null}
      </PageContext.Provider>
    </Layout>
  );
};

export default ExtQuestionnaire;

export const query = graphql`
  query ($language: String!, $i18Key: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: $i18Key } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
