import React, { useContext } from "react";
import "./index.scss";
import TrackedLink from "../../Analytics/TrackedLink";
import PropTypes from "prop-types";
import { PageContext } from "../../../store/context/page-context";
import { SECTION_VIEW_TWO_TEXT } from "./index.content";

const ListItem = ({ children }) =>
  children.length > 0 ? (
    <li className="offerView__title offerView__title_bg">{children}</li>
  ) : null;

ListItem.prototype = {
  children: PropTypes.string,
};

const OfferView = ({ isFirstView }) => {
  const { eventCategory, isCn, page, translate: t } = useContext(PageContext);

  const keySectionViewTwo = Object.keys(SECTION_VIEW_TWO_TEXT).includes(page)
    ? page
    : "common";

  const { firstTitle, mainTitle, secondTitle, bottomTitle, button } =
    SECTION_VIEW_TWO_TEXT[keySectionViewTwo];

  const sectionViewOne = () => (
    <>
      {isCn && t("BigOfferView_CnVersion").length > 0 ? (
        <p className="offerView__title offerView__title_bg offerView__title_bg-cn">
          {t("BigOfferView_CnVersion")}
        </p>
      ) : (
        <>
          <p className="offerView__title offerView__title_sm">
            {t("BigOfferView_FirstTitle")}
          </p>
          <p className="offerView__title offerView__title_bg">
            <span>{t("BigOfferView_MainTitle")}</span>
          </p>
          <ul>
            <ListItem>{t("BigOfferView_List_FirstItem")}</ListItem>
            <ListItem>{t("BigOfferView_List_SecondItem")}</ListItem>
            <ListItem>{t("BigOfferView_List_ThirdItem")}</ListItem>
            <ListItem>{t("BigOfferView_List_FourthItem")}</ListItem>
          </ul>
          <p className="offerView__title offerView__title_bg">
            {`${t("BigOfferView_List_LastItem")} `}
            <span>{t("BigOfferView_List_LastItem_Price")}</span>
          </p>
          <p className="offerView__title offerView__title_ds">{`${t(
            "BigOfferView_Bottom_Tittle"
          )}!`}</p>
        </>
      )}
      <TrackedLink
        class="offerView__btn"
        category={eventCategory}
        action="Get it now"
        path={t("BigOfferView_Button_Path")}
        target={!isCn}
        redash={false}
      >
        {t("BigOfferView_Button")}
      </TrackedLink>
    </>
  );

  const sectionViewTwo = () => (
    <>
      <p className="offerView__title offerView__title_sm">{t(firstTitle)}</p>
      <p className="offerView__title offerView__title_bg">
        {`${t(mainTitle.title)} `}
        <span>{t(mainTitle.price)}</span> {t(mainTitle.afterPrice)}
      </p>
      <p className="offerView__title offerView__title_bg">{t(secondTitle)}</p>
      <p className="offerView__title offerView__title_ds">{t(bottomTitle)}</p>
      <TrackedLink
        class="offerView__btn"
        category={eventCategory}
        action="Get it now"
        path={t(button.path)}
        redash={false}
      >
        {t(button.text)}
      </TrackedLink>
    </>
  );

  return (
    <section className="offerView">
      <p className="offerView__title offerView__title_md">
        {t("OfferView_TopTitle")}
      </p>
      {isFirstView ? sectionViewOne() : sectionViewTwo()}
    </section>
  );
};

OfferView.prototype = {
  isFirstView: PropTypes.bool,
};

export default OfferView;
