import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { trackGa } from "../../tracking/googleAnalytics";
import { PageContext } from "../../store/context/page-context";
import { updateContent } from "../../components/ext-questionnaire/Select/updateContent";
import { USER_STATUSES } from "../../components/ext-questionnaire/index.content";
import SelectView from "../../components/ext-questionnaire/Select";

const NUMBERS = {
  option1: 1,
  option2: 2,
  option3: 3,
  option4: 4,
};

const SelectViewContainer = ({ switchCurrentView }) => {
  const { eventCategory, status, page, translate: t } = useContext(PageContext);

  const [getSelectState, setSelectState] = useState(null);
  const content = updateContent(t);
  let key = "common";
  if ((page === "extpro") && status === USER_STATUSES.p) {
    key = "paid";
  }
  if (page === "extnewpro") {
    key = "paid";
  }
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectState(value);

    trackGa("select", {
      category: eventCategory,
      label: value,
    });

    const switchNumber = NUMBERS[value] || 0;

    setTimeout(() => {
      switchCurrentView(switchNumber);
    }, 800);
  };

  return (
    <SelectView
      getSelectState={getSelectState}
      handleSelectChange={handleSelectChange}
      selectFormContent={content[key]}
    />
  );
};

SelectViewContainer.propTypes = {
  switchCurrentView: PropTypes.func.isRequired,
};

export default SelectViewContainer;
